//
// News CSS
//

// list view

.news {

  h2 {
    margin-bottom: 0;
  }

  background: $white;
  padding: 20px 0;

  .news-list-view {
    .news-img-wrap a {
      display: block;
    }
    h3 {
      @include font-size(16);
      font-weight: 600;
      margin: 2px 0 0 0;
      a {
        color: $grey-dark;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
    .news-list-date {
      @include font-size(12);
      margin: 0 0 2px 0;
    }
    .teaser-text {
      p {
        line-height: 1.2em;
        font-weight: 300;
        margin: 0;
      }
    }
    .more {
      text-transform: uppercase;
      font-weight: 600;
      color: $grey-dark;
      text-decoration: none;
    }
  }

  // Default News List
  .news-list-view.layout-0 {
    .article {
      overflow: hidden;

      .news-img-wrap {
        float: left;
        margin-right: 20px;
      }
    }
  }


  // Start page News in compact view
  .news-list-view.layout-3 {
    overflow: hidden;
    max-width: $maxPageWidthInner;
    .news-list-items-wrap {
      overflow: hidden;
    }
    .article {
      float: left;
      width: 32%;
      margin-left: 2%;
      &:first-child {
        margin-left: 0;
      }
      a:hover {
        color: $grey;
      }
      .news-img-wrap a {
        display: block;
      }
      h3 {
        @include font-size(16);
        font-weight: 600;
        margin: 2px 0 0 0;
        a {
          color: $grey-dark;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
      .news-list-date {
        @include font-size(12);
        margin: 0 0 2px 0;
      }
      .teaser-text {
        p {
          line-height: 1.2em;
          font-weight: 300;
          margin: 0;
        }
      }
      .more {
        text-transform: uppercase;
        font-weight: 600;
        color: $grey-dark;
        text-decoration: none;
      }
    }
    .divider {
      display: none;
    }

    @media screen and (max-width: $size-S2 ) {
      .article {
        float: none;
        width: auto;
        margin: 0 0 20px 0;
      }
    }
  }

}


// pagination

.f3-widget-paginator {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;

  li {
    padding: 0 8px;
  }
}


// single view

.news-single {
  .news-content-wrap {
    overflow: hidden;
  }
  .teaser-text {
    font-weight: 600;
    margin-bottom: .5em;
  }
  .news-img-wrap {
    float: left;
    margin-right: 20px;
  }
  .header {
    padding-bottom: 30px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 30px;
  }
  h1 {
    //margin: 0;
  }
  .facebook {
    .like,
    .share,
    .twitter {
      margin-bottom: 10px;
    }
  }
  .news-backlink-wrap {
    clear: both;
    padding-top: 20px;
    a {
      color: $grey-font;
    }
  }
}


// sharif
.shariff {
  margin-top: 20px;

  .shariff-button {
    color: $white;
    a {
      color: $white !important;
      text-decoration: none !important;
    }
  }
}