//
// Events CSS
//

// list view

.events {

  h2 {
    margin: 0;
  }

  background: $white;

  .news-list-view.layout-4 {
    overflow: hidden;
    max-width: $maxPageWidthInner;
    .news-list-items-wrap {
      overflow: hidden;
    }
    .article {
      float: left;
      width: 32%;
      margin-left: 2%;
      &:first-child {
        margin-left: 0;
      }
      a:hover {
        color: $grey;
      }
      .news-img-wrap a {
        display: block;
      }
      h3 {
        @include font-size(16);
        font-weight: 600;
        margin: 2px 0 0 0;
        a {
          color: $grey-dark;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
      .news-list-date {
        @include font-size(12);
        margin: 0 0 2px 0;
      }
      .teaser-text {
        p {
          line-height: 1.2em;
          font-weight: 300;
          margin: 0;
        }
      }
      .more {
        text-transform: uppercase;
        font-weight: 600;
        color: $grey-dark;
        text-decoration: none;
      }
    }
    .divider {
      display: none;
    }

    @media screen and (max-width: $size-S2 ) {
      .article {
        float: none;
        width: auto;
        margin: 0 0 20px 0;
      }
    }

  }
}



// pagination

.page-navigation {
  clear: both;
  ul.f3-widget-paginator {
    list-style: none;
    overflow: hidden;
    font-weight: 400;
    padding: 0;
    li {
      float: left;
      a {
        padding: 0 10px;
        &:hover {
          background: $grey;
          color: $white;
        }
      }
      &.current {
        padding: 0 10px;
      }
    }
  }
}


// single view

.news-single {
  .news-content-wrap {
    overflow: hidden;
  }
  .news-content {
    float: left;
    width: 74.5%;
    margin-right: 2%;
  }
  .sidebar {
    float: left;
    width: 23.5%;
  }
  .header {
    padding-bottom: 30px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 30px;
  }
  h1 {
    @include font-size(50);
    font-weight: 100;
    letter-spacing: -1px;
    margin: 0;
  }
  .facebook {
    .like,
    .share,
    .twitter {
      margin-bottom: 10px;
    }
  }
}