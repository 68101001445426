//
// Import Base CSS and add custom
//

// Base Settings
@import "base/fonts";
@import "base/settings";
@import "base/colors";

// Mixins
@import "base/mixins";

// Normalize
@import "base/normalize";

// Add global CSS
@import "base/global";

// Add Magnific Popup
@import "lib/magnific_popup/main";


//
// CUSTOM CSS
//

h1 {
  text-transform: uppercase;
  @include font-size(36);
  margin: 0 0 0.5em 0;
  line-height: 1.2;
}

.start-page h1 {
  color: $white;
  font-weight: 300;
  text-transform: uppercase;
  @include font-size(55);
  margin: 0 0 .2em 0;
  line-height: 1.2;

  @media screen and (max-width: $size-M ) {
    @include font-size(36);
  }

  @media screen and (max-width: $size-XS3 ) {
    @include font-size(28);
  }

}

h2 {
  @include font-size(36);
  line-height: 1;
  text-transform: uppercase;
  color: $grey;
  margin: 0 0 0.5em 0;
}

p {
  margin: 0 0 1em 0;
  &:last-child {
    margin: 0;
  }
}


// general
body {
  font-family: $baseFont;
  @include font-size(14);
  background: $white;
  color: $grey-font;
}

.page {
  margin: auto;
}


// start page header images
.page-id-2 {
  .key-visual {
    img {
      width: 100%;
      max-width: none;
    }
  }
}


// special background in page companies and sub
.page-id-6 .page,
.pid-6 .page {
  background: #cfd0d1;
  section.main {
    .content-main {
      padding: 0;
    }
  }
}

@import "csc_overrides";


@import "header";
@import "slider";
@import "nav_main";
@import "nav_sub";
@import "search_box";
@import "nav_areas";
@import "footer";
@import "nav_footer";
@import "nav_footer_sitemap";

@import "content";
@import "intro";
@import "news";
@import "events";
@import "locations";
@import "search";
@import "video";