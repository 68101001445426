//
// Footer CSS
//

.page > footer {

  background: $grey-footer;

  .footer-inner {
    max-width: $maxPageWidthInner;
    margin: auto;
    padding: 0 20px;
  }

  .footer-mid {
    padding: 20px 30px;
    border-top: 1px solid $grey-line;
    border-bottom: 1px solid $grey-line;
  }

  .footer-text {
    padding: 50px 30px 20px 30px;
    @include font-size(14);
    color: $grey-light;
    a {
      text-decoration: none;
      color: $grey-light;
    }
  }

  @media screen and (max-width: $size-M ) {

    .footer-inner {
      padding: 0;
    }

    .footer-text,
    .footer-mid {
      padding: 20px;
    }

  }

}