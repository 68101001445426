//
// header CSS
//

.page > header {

}

.header-top {
  max-width: 800px;
  margin: auto;
  background: $grey-dark;
  height: 78px;
  border-bottom: 1px solid $white;

  .burger-icon {
    display: none;
    padding: 10px;
    border: 1px solid $white;
    border-radius: 8px;
    float: left;
    margin: 20px 20px 0 30px;
    width: 55px;
    cursor: pointer;
    div {
      height: 4px;
      background: $white;
      margin-top: 3px;
      &:first-child {
        margin: 0;
      }
    }
    @media screen and (max-width: $size-SM ) {
      display: block;
    }
  }

  .logo {
    float: right;
  }
}

.key-visual {
  position: relative;

  max-width: $maxPageWidth;
  margin: auto;

  img {
    display: block;
    width: 100%;
  }

  figure {
    margin: 0;
  }

  .logo {
    position: absolute;
    left: 0;
    top: 56%;
    width: 41%;
  }

  @media screen and (max-width: $size-XXL ) {
    .logo {
      display: none;
    }
  }

}

// start page
.start-page > header {
  background: #cfd0d1;
}

.start-page .key-visual {

  &:after {
    content: "";
    display: block;
    height: 100px;
    background: url("../Images/startpage_gradient.png") repeat-x;
  }

  @media screen and (max-width: $size-XXL ) {
    &:after {
      height: 70px;
    }
  }

  @media screen and (max-width: $size-S3 ) {
    &:after {
      display: none;
    }
  }

}