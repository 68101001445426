//
// Nav Sub CSS
//

.nav-sub {
  border-top: 1px solid $white;
  //background: $grey-light-nav;
  background: #adaeaf;
  margin-top: -46px;
  padding-top: 46px;
  min-height: 100px;
  ul {
    margin: auto;
    padding: 10px 0 10px 58px;
    max-width: 800px;
    overflow: hidden;
    list-style: none;
    li {
      float: left;
      margin: 0 0 0 15px;
      a {
        color: $white;
        text-decoration: none;
        &.active,
        &:hover {
          color: $red;
        }
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: $size-XXL ) {
    min-height: 70px;
  }

  @media screen and (max-width: $size-M ) {
    ul {
      padding-left: 25px;
    }
  }

  @media screen and (max-width: $size-SM ) {
    display: none;
    margin-top: 0;
    padding-top: 0;
  }

}