//
// Search Box CSS
//

.search-box {

  float: left;
  margin: 30px 0 0 20px;

  fieldset {
    border: 1px solid #CCC;
    border-radius: 10px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
  }

  .searchbox-sword {
    background: none;
    border: none;
    padding: 1px 15px;
    color: #FFF;
  }

  .tx-indexedsearch-form {
    width: 180px;

    label {
      display: none;
    }
  }

  .searchbox-submit-btn {
    background: url("../Svgs/magnifier.svg") no-repeat center center;
    background-size: 16px;
    overflow: hidden;
    text-indent: -9999px;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 21px;
  }

  @media screen and (max-width: $size-S3 ) {
    display: none;
  }

}