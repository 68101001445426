//
// Nav Main CSS
//

.nav-main {

  position: relative;
  max-width: 800px;
  height: 45px;
  margin: auto;
  padding: 3px 0 0 0;
  background: $grey-dark;
  border-radius: 0 0 10px 10px;
  display: block;

  ul {
    margin: 0;
    padding: 0 0 0 50px;
    overflow: hidden;
    list-style: none;
    li {
      float: left;
      margin: 0 0 0 5px;

      span {
        display: none;
      }

      a {
        display: block;
        padding: 0 5px;
        line-height: 1.8;
        text-decoration: none;
        color: $grey-nav-main;
        @include font-size(20);
        text-transform: uppercase;
        &.active,
        &:hover {
          color: $red;
        }
      }
      &:first-child {
        margin-left: 0;
      }

      // level 2
      ul {
        display: none;
      }
    }
  }

  @media screen and (max-width: $size-M ) {
    ul {
      padding-left: 15px;
    }
  }

  @media screen and (min-width: $size-SM ) {
    display: block !important;
  }

  @media screen and (max-width: $size-SM ) {
    display: none;
    height: auto;
    border-radius: 0;
    background: $grey-light-content;
    position: absolute;
    width: 100%;
    top: 78px;
    z-index: 9;
    padding: 0 0 10px 0;
    -moz-box-shaddow: 3px 3px 7px #333;
    -webkit-box-shaddow: 3px 3px 7px #333;
    box-shadow: 3px 3px 7px #333;

    ul {
      padding: 0;

      li {
        display: block;
        float: none;
        position: relative;
        border-top: 1px solid $grey-line;
        margin-left: 0;

        &:first-child {
          border: none;
        }

        span {
          display: block;
          position: absolute;
          right: 5px;
          top: 4px;
          cursor: pointer;
          @include font-size(30);
          padding: 0 20px;
          background: url("../Svgs/arrow.svg") no-repeat center center;
          background-size: 28px;
          overflow: hidden;
          text-indent: -99999px;
          -ms-transform:rotate(270deg); /* IE 9 */
          -moz-transform:rotate(270deg); /* Firefox */
          -webkit-transform:rotate(270deg); /* Safari and Chrome */
          -o-transform:rotate(270deg); /* Opera */
        }

        &.open span {
          -ms-transform:rotate(0deg); /* IE 9 */
          -moz-transform:rotate(0deg); /* Firefox */
          -webkit-transform:rotate(0deg); /* Safari and Chrome */
          -o-transform:rotate(0deg); /* Opera */
        }

        a {
          display: block;
          padding: 0 25px;
          color: $grey-dark;
          line-height: 2.4;
          &:hover {
            background: $grey-light;
          }
        }


        // level 2
        ul {
          padding-left: 20px;
          padding-bottom: 10px;
          li {
            border: none;

            a {
              text-transform: none;
              line-height: 1.8;
            }
          }
        }

      }

    }

  }

}