//
// Search CSS
//

.tx-indexedsearch-searchbox,
.tx-indexedsearch-rules {
  display: none;
}

.tx-indexedsearch {
  .browsebox {
    margin-left: 0;
    padding-left: 0;
  }
}

ul.tx-indexedsearch-browsebox {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;

  li {
    padding-right: 10px;
  }
}

.tx-indexedsearch-info {
  display: none;
}