//
// Project Color Definitions
//

$grey-dark: #65696b;
$grey: #666666;
$grey-font: #4d4d4d;
$grey-nav-main: #f3f3f3;
$grey-light-nav: #adaeaf;
$grey-light-content: #cfd0d1;
$grey-light: #c6c4bd;
$grey-line: #727475;
$grey-footer: #42474c;
$green: #1aae56;
$red: #e3001f;
$white: #FFFFFF;
$black: #000000;
$bgTr: #e8ecf2;