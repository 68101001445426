//
// Defaul Project Settings
//

$maxPageWidth: 1920px;
$maxPageWidthInner: 900px;
$baseFont: 'Open Sans Condensed', sans-serif;



// brakepoints
$size-XXL: 1250px;
$size-L2: 930px;
$size-L: 900px;
$size-M3: 840px;
$size-M: 760px;
$size-SM: 690px;
$size-S3: 620px;
$size-S2: 560px;
$size-S: 540px;
$size-XS4: 500px;
$size-XS3: 470px;
$size-XS2: 450px;
$size-XS: 400px;

