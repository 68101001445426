//
// Content CSS
//


section.main {

  .content-main {
    padding: 0 20px 30px 20px;

    a {
      color: $grey;
      text-decoration: underline;
    }

    @media screen and (max-width: $size-M ) {

        h1,
        h2 {
          @include font-size(28);
          text-transform: none;
        }

    }

    @media screen and (max-width: $size-S3 ) {

      h1,
      h2 {
        @include font-size(24);
        text-transform: none;
      }

    }

  }

  //background: $white;
  .bg-grey {
    background: $grey-light-content;
    padding: 20px 0;
  }
  .csc-default-inner,
  .ce-inner {
    max-width: $maxPageWidthInner;
    margin: auto;
  }
  .full-width .csc-default-inner,
  .full-width .ce-inner {
    max-width: none;
    margin: 0;
    img {
      display: block;
    }
  }

  .csc-textpic-text a {
    color: $grey;
    text-decoration: underline;
  }

}

.divider {
  margin: 0 -20px;
  padding: 30px 0;
}
.frame-type-div {
  padding: 30px 0;

  hr {
    margin: 0 -20px;
  }
}


.content-header {

  margin: auto;
  padding-bottom: 15px;
  max-width: 900px;

  div.csc-textpic .csc-textpic-imagewrap .csc-textpic-image {
    margin: 0;
  }

  .ce-gallery {
    margin-bottom: 0;
  }

  .csc-default,
  .frame {

    &:first-child {
      .csc-default-inner,
      .ce-inner {
        padding: 0;
      }
    }

    &:last-child {

      //margin: 0 -20px;
      //padding: 0 20px;

      .csc-default-inner,
      .ce-inner {
        padding: 15px 20px;
        background: #525f66;
        color: $white;
        border-radius: 0 0 15px 15px;
        h1 {
          @include font-size(36);
          text-transform: none;
          color: $white;
        }
        h2 {
          @include font-size(20);
          font-weight: normal;
          margin: 0;
          color: $white;
        }


        @media screen and (max-width: $size-M ) {

          border-radius: 0;
          h1 {
            @include font-size(28);
            text-transform: none;
          }
          h2 {
            @include font-size(16);
          }
        }

        @media screen and (max-width: $size-S3 ) {

          padding: 15px 20px;
          h1 {
            @include font-size(24);
            text-transform: none;
          }
          h2 {
            @include font-size(14);
          }
        }


      }

    }
  }

}


@media screen and (max-width: $size-S3 ) {

  .content-main {

    div.csc-textpic-intext-left-nowrap .csc-textpic-imagewrap {
      float: none;
    }

    .csc-textpic-intext-left-nowrap .csc-textpic-text {
      margin: 0;
    }

    .ce-left .ce-gallery, .ce-column {
      float: none;
    }

  }

}




// defaul page
.default-page {

  .ce-inner {
    padding: 0 20px;
  }
}


// start page
.start-page section.main {
  .content-main {
    padding: 0;
    .ce-inner {
      max-width: 1500px;
      figure {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: $size-L2 ) {
    section {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}


// Video
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16x9 */
  overflow: hidden;
  width: 100%;
  height: auto;
  margin: auto;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}