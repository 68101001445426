//
// Nav Footer Sitemap CSS
//

.nav-footer-sitemap {
  max-width: $maxPageWidthInner;
  margin: auto;
  padding: 50px 30px 20px 30px;

  ul {
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      float: left;
      //margin-left: 15px;
      width: 20%;
      &:first-child {
        margin-left: 0;
      }
      a {
        font-weight: 400;
        //font-family: $specialFont;
        color: $grey-light;
        @include font-size(20);
        text-decoration: none;
        color: $green;
        &.active,
        &:hover {
          //color: $blue;
        }
      }

      // level 2
      ul {
        li {
          float: none;
          margin: 0;
          width: auto;
          a {
            color: $grey-light;
            @include font-size(14);
            &.active,
            &:hover {
              //color: $blue;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $size-M ) {
    display: none;
  }

}