//
// Nav Footer CSS
//


.nav-footer {

  ul {
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      float: left;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
      a {
        font-weight: 400;
        @include font-size(16);
        text-decoration: none;
        text-transform: uppercase;
        color: $grey-light;
        &:hover,
        &.active {
          //color: $blue;
        }
      }
    }
  }

}