// Video

.video-area {
  max-width: $maxPageWidthInner;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }

  .external-video-consent {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($white, .85);
    padding: 20px 30px;
    display: none;

    &.show {
      display: block;
    }

    a {
      color: $black;
    }

    .btn-group {
      margin-top: 10px;
      display: flex;
      gap: 10px;
    }

    button {
      border: none;
      background: rgba($grey, .7);
      color: $white;
      border-radius: 3px;
      padding: 6px 15px;

      &:hover {
        background: rgba($grey, 1);
      }
    }
  }

}

// override FSC CSS as display table prevent from 100% width
.ce-gallery figure.video {
  display: block;
}
.frame-layout-2 {
  .ce-left .ce-gallery,
  .ce-column {
    float: none;
  }
}

.video-keep-data-privacy {
  display: block;
  position: relative;

  img {
    display: block;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url("../Svgs/youtube-play-button.svg") no-repeat center center;
    background-size: 80px;
    opacity: .8;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

}
