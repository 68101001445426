//
// Nav Areas CSS
//

// on overview page Firmen add nav-sub in nav area style as well
.pid-6 .nav-sub,
.page-id-6 .nav-sub,
.nav-areas {

  background: $grey-light-content;

  ul {
    margin: auto;
    width: 560px;
    padding: 0;
    overflow: hidden;
    list-style: none;

    li {
      float: left;
      margin: 0 0 0 15px;

      a {
        position: relative;
        display: block;
        width: 100px;
        height: 180px;
        padding: 110px 0 0 0;
        line-height: 1.1;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        color: $grey-dark;
        @include font-size(13);
        background: url("../Svgs/dienstleister.svg") no-repeat;
        z-index: 3;
        &:hover,
        &.active {
          background-image: url("../Svgs/dienstleister_act.svg");
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 100px;
          left: -5px;
          width: 105px;
          height: 70px;
          background: url("../Images/area_icon_shaddow.png") no-repeat 0 0;
          z-index: -1;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:nth-child(2) a {
        background-image: url("../Svgs/handwerk.svg");
        &:hover,
        &.active {
          background-image: url("../Svgs/handwerk_act.svg");
        }
      }
      &:nth-child(3) a {
        background-image: url("../Svgs/einzelhandel.svg");
        &:hover,
        &.active {
          background-image: url("../Svgs/einzelhandel_act.svg");
        }
      }
      &:nth-child(4) a {
        background-image: url("../Svgs/essen.svg");
        &:hover,
        &.active {
          background-image: url("../Svgs/essen_act.svg");
        }
      }
      &:nth-child(5) a {
        background-image: url("../Svgs/auto.svg");
        &:hover,
        &.active {
          background-image: url("../Svgs/auto_act.svg");
        }
      }
    }
  }

  @media screen and (max-width: $size-SM ) {
    display: block;
  }

  @media screen and (max-width: $size-S3 ) {

    border-top: 1px solid #fff;
    padding-top: 25px;

    ul {
      width: 460px;

      li {

        a {
          padding-top: 88px;
          width: 80px;
          height: 160px;
          @include font-size(12);

          &:after {
            display: none;
          }
        }

      }

    }

  }

}


.page-id-6 .nav-sub,
.nav-areas {

  @media screen and (max-width: $size-XS3 ) {

    border: none;
    padding-bottom: 20px;
    padding-top: 0;

    ul {
      width: auto;

      li {
        float: none;
        margin: 0;
        border-bottom: 1px solid $white;

        &:first-child {
          border-top: 1px solid $white;
        }

        background-color: #849fcb;
        background-image: -webkit-linear-gradient(top, #849fcb, #122c4d);
        background-image: -moz-linear-gradient(top, #849fcb, #122c4d);
        background-image: -ms-linear-gradient(top, #849fcb, #122c4d);
        background-image: -o-linear-gradient(top, #849fcb, #122c4d);
        background-image: linear-gradient(top, #849fcb, #122c4d);

        a,
        a:hover {

          color: $white;
          width: auto;
          background: url("../Svgs/dienst_rund.svg") no-repeat 30px center;
          background-size: 50px;
          text-align: left;
          padding-left: 100px;
          @include font-size(16);
          padding-top: 0;
          line-height: 60px;
          height: 60px;
          overflow: hidden;

          br {
            display: none;
          }

        }

        &:nth-child(2) {
          background-color: #90c4d6;
          background-image: -webkit-linear-gradient(top, #90c4d6, #183f53);
          background-image: -moz-linear-gradient(top, #90c4d6, #183f53);
          background-image: -ms-linear-gradient(top, #90c4d6, #183f53);
          background-image: -o-linear-gradient(top, #90c4d6, #183f53);
          background-image: linear-gradient(top, #90c4d6, #183f53);
          a,
          a:hover {
            background-image: url("../Svgs/industrie_rund.svg");
          }
        }

        &:nth-child(3) {
          background-color: #79c0c3;
          background-image: -webkit-linear-gradient(top, #79c0c3, #164952);
          background-image: -moz-linear-gradient(top, #79c0c3, #164952);
          background-image: -ms-linear-gradient(top, #79c0c3, #164952);
          background-image: -o-linear-gradient(top, #79c0c3, #164952);
          background-image: linear-gradient(top, #79c0c3, #164952);
          a,
          a:hover {
            background-image: url("../Svgs/handel_rund.svg");
          }
        }

        &:nth-child(4) {
          background-color: #de8d5a;
          background-image: -webkit-linear-gradient(top, #de8d5a, #55250b);
          background-image: -moz-linear-gradient(top, #de8d5a, #55250b);
          background-image: -ms-linear-gradient(top, #de8d5a, #55250b);
          background-image: -o-linear-gradient(top, #de8d5a, #55250b);
          background-image: linear-gradient(top, #de8d5a, #55250b);
          a,
          a:hover {
            background-image: url("../Svgs/essen_rund.svg");
          }
        }

        &:nth-child(5) {
          background-color: #f3dd33;
          background-image: -webkit-linear-gradient(top, #f3dd33, #c28c0b);
          background-image: -moz-linear-gradient(top, #f3dd33, #c28c0b);
          background-image: -ms-linear-gradient(top, #f3dd33, #c28c0b);
          background-image: -o-linear-gradient(top, #f3dd33, #c28c0b);
          background-image: linear-gradient(top, #f3dd33, #c28c0b);
          a,
          a:hover {
            background-image: url("../Svgs/auto_rund.svg");
          }
        }

      }

    }

  }

}


.page-id-6 .nav-sub,
.pid-6 .nav-sub {
  height: auto;
  padding-top: 70px;

  @media screen and (max-width: $size-SM ) {
    display: block;
    padding-top: 25px
  }

}


.pid-6 .nav-sub {

  border-top: 1px solid #fff;

  ul {
    width: 460px;

    li {

      a {
        padding-top: 88px;
        width: 80px;
        height: 140px;
        @include font-size(12);

        &:after {
          display: none;
        }
      }

    }

  }

  @media screen and (max-width: $size-XS3 ) {
    padding-top: 10px;
    ul {
      width: 320px;
      height: 70px;

      li {

        margin-left: 5px;

        a {
          padding-top: 88px;
          width: 60px;
          height: 140px;
          @include font-size(12);

          &:after {
            display: none;
          }
        }

      }

    }
  }

}

/*
.pid-6 .nav-sub {

  padding-bottom: 15px;

  ul {
    margin: auto;
    max-width: 740px;
    padding: 0;
    overflow: hidden;
    list-style: none;

    li {
      float: left;
      margin: 0 0 0 10px;

      a {
        position: relative;
        display: block;
        width: 140px;
        min-height: 44px;
        padding: 14px 0 0 0;
        line-height: 1.1;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        color: $white;
        @include font-size(13);
        background: url("../Svgs/diensteister_s.svg") no-repeat;
        z-index: 3;

        a:hover {
          background: url("../Svgs/highlight_s.svg") no-repeat;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:nth-child(2) a {
        background-image: url("../Svgs/industrie_s.svg");
        padding-top: 7px;
      }
      &:nth-child(3) a {
        background-image: url("../Svgs/einzelhandel_s.svg");
      }
      &:nth-child(4) a {
        background-image: url("../Svgs/essen_s.svg");
        padding-top: 7px;
      }
      &:nth-child(5) a {
        background-image: url("../Svgs/auto_s.svg");
      }
    }
    li a:hover,
    li a.active {
      background-image: url("../Svgs/highlight_s.svg");
    }
  }

  @media screen and (max-width: $size-M ) {

    margin-top: 0;

    ul {
      padding-left: 20px;
      li {
        margin-left: 5px;
        a {
          width: 114px;
          padding-top: 10px;
        }
        &:first-child {
          margin-left: 0;
        }
        &:nth-child(2) a,
        &:nth-child(4) a {
          padding-top: 3px;
        }
      }
    }
  }

  @media screen and (max-width: $size-S3 ) {

    border-top: 1px solid #fff;
    padding-top: 25px;

    ul {
      width: 460px;

      li {

        &:first-child {
          margin-left: 0;
        }
        &:nth-child(2) a {
          background-image: url("../Svgs/handwerk.svg");
          &:hover,
          &.active {
            background-image: url("../Svgs/handwerk_act.svg");
          }
        }
        &:nth-child(3) a {
          background-image: url("../Svgs/einzelhandel.svg");
          &:hover,
          &.active {
            background-image: url("../Svgs/einzelhandel_act.svg");
          }
        }
        &:nth-child(4) a {
          background-image: url("../Svgs/essen.svg");
          &:hover,
          &.active {
            background-image: url("../Svgs/essen_act.svg");
          }
        }
        &:nth-child(5) a {
          background-image: url("../Svgs/auto.svg");
          &:hover,
          &.active {
            background-image: url("../Svgs/auto_act.svg");
          }
        }

        a {
          padding-top: 88px;
          width: 80px;
          height: 160px;
          @include font-size(12);

          &:after {
            display: none;
          }
        }

      }

    }

  }

}
*/