//
// Global CSS
//


// apply a natural box layout model to all elements, but allowing components to change
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}


// ----- HTML-Elemente -----

body {
  margin: 0;
}

img {
  border-style: none;
}

hr {
  border: none;
  background-color: $grey-line;
  height: 1px;
  display: block;
  margin: 0;
  margin-bottom: $grey-line;
  padding: 0;
}

// set some elements responsive
img, object, embed, video {
  height: auto;
  max-width: 100%;
}

// and the hack for IE8
@media \0screen {
  img, object, embed, video {
    width: auto; /* for ie 8 */
  }
}


// ----- helper css classes -----

.clearfix {
  @include clearfix;
}

.hide-text {
  @include hide-text;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  @include list-inline;
}

.invisible {
  display: none !important;
}

.no-margin {
  margin: 0 !important;
}

.right-float {
  float: right;
}

.vertical-align-sub {
  vertical-align: sub;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.visually-hidden {
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  a:focus,
  input:focus,
  button:focus {
    position: static;
    width: auto;
    height:auto;
  }
}