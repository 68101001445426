//
// Locations / companies CSS
//


.location-list {

  padding-top: 40px;
  padding-bottom: 40px;

  ul {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    margin: -30px;

    li {
      width: calc(100% / 3);
      padding: 30px;

      .location-image {
        overflow: hidden;
        a {
          display: block;
        }
        img:hover {
          opacity: .7;
        }
      }

      img {
        display: block;
      }

      .location-list-info {
        height: 130px;
        background: #525f66;
        color: $white;
        padding: 5px 10px;
        line-height: 1.2;
        border-radius: 0 0 12px 12px;

        h3 {
          margin: 0 0 5px 0;
          @include font-size(14);
        }
        a {
          color: $white;
          text-decoration: none;
        }
      }

    }

  }


  @media screen and (max-width: $size-L2) {
    ul {
      margin: -20px;

      li {
       padding: 20px;
      }
    }
  }

  @media screen and (max-width: $size-M3) {
    ul {

      li {
        width: calc(100% / 2);
        max-width: 300px;

        .location-image,
        .location-list-info {
          margin: auto;
        }
      }
    }
  }

  @media screen and (max-width: $size-S) {
    ul {
      margin: auto;
      display: block;
      max-width: 260px;

      li {
        width: auto;
        padding: 0;
        margin-bottom: 20px;
      }
    }
  }


}

.location-category-filter {
  max-width: 740px;
  margin: auto;

  .location-category-filter-label {
    display: none;
  }

  ul {
    overflow: hidden;
    list-style: none;
    margin: 15px 0 30px 0;
    padding: 0 20px;
    li {
      float: left;
      width: 25%;
      cursor: pointer;
      color: $black;
      &:hover,
      &.active {
        color: $red;
      }
    }
  }

  // active filter drop down display
  @media screen and (max-width: $size-S3 ) {

    .location-category-filter-label {
      display: block;
      background: #e3001f url("../Svgs/arrow.svg") no-repeat scroll 97% center / 20px auto;
      //background-size: 12px;
      color: $white;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      @include font-size(16);
      font-weight: 600;
      line-height: 1.6;
      -moz-box-shaddow: 3px 3px 7px #333;
      -webkit-box-shaddow: 3px 3px 7px #333;
      box-shadow: 3px 3px 7px #333;
    }

    ul {
      display: none;
      margin: 0;
      padding: 0 0 10px 0;

      li {
        float: none;
        width: auto;
        border-top: 1px solid $grey-dark;
        padding: 5px 30px;
        margin: 0;
        font-weight: 600;
      }
    }

  }

}

#map-canvas {
  height: 400px;
  width: 100%;
  max-width: 900px;

  @media screen and (max-width: $size-S3 ) {
    height: 250px;
  }
}


.tx-pv-locator .key-visual {
  background: none;
  padding-bottom: 0;

  .csc-textpic-imagecolumn {
    margin: 0;
  }
}


// location detail
.location-detail {

  margin: 0 -20px;

  .location-header {
    padding: 15px 20px;
    background: #525f66;
    color: $white;
    border-radius: 0 0 15px 15px;
    h1 {
      @include font-size(36);
      text-transform: none;
    }
    h3 {
      @include font-size(20);
      font-weight: normal;
      margin: 0;
    }
  }

  .location-detail-info {
    overflow: hidden;
    padding: 20px 20px 0 20px;

    .location-detail-logo {
      float: left;
      width: 33.333333%;
    }

    .location-detail-contact {
      float: left;
      width: 33.333333%;
      padding-left: 2%;
      .label {
        text-transform: uppercase;
        font-weight: bold;
        @include font-size(20);
        margin-bottom: 10px;
      }
      p {
        margin: 0;
        &:last-child {
          margin-top: 10px;
        }
      }
      a {
        text-decoration: none;
        color: red;

        &[target="_blank"] {
          padding-left: 26px;
          background: url('../Images/icon_link_external.svg') no-repeat left center;
          background-size: 20px;
        }
      }
    }

    .location-detail-opening-hours {
      float: left;
      width: 33.333333%;
      padding-left: 2%;
      .label {
        text-transform: uppercase;
        @include font-size(18);
      }
    }

  }


  @media screen and (max-width: $size-M ) {

    .location-header {
      border-radius: 0;
      h1 {
        @include font-size(28);
        text-transform: none;
      }
      h3 {
        @include font-size(16);
        font-weight: normal;
        margin: 0;
      }
    }

    .location-detail-info {

      .location-detail-contact {
        width: 66.66666%;
      }

      .location-detail-opening-hours {
        float: none;
        clear: both;
        width: auto;
        padding-top: 15px;
      }

    }

  }

  @media screen and (max-width: $size-S3 ) {

    .location-header {
      padding: 15px 20px;
      h1 {
        @include font-size(24);
        text-transform: none;
      }
      h3 {
        @include font-size(14);
        font-weight: normal;
        margin: 0;
      }
    }

    .location-detail-info {

      .location-detail-logo {
        float: none;
        width: auto;
      }

      .location-detail-contact {
        float: none;
        clear: both;
        width: auto;
        padding-top: 15px;
      }

    }

  }

}