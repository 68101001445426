//
// CSC Styled Content Overrides CSS
//

div.csc-textpic-above .csc-textpic-imagewrap {
  float: none;

  figure {
    display: block;
  }
}

.ce-above .ce-gallery {
  margin-bottom: 20px;
}
