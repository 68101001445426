//
// Slider CSS
//

@import "lib/slick_slider/base";

.key-visual {
  box-sizing: border-box;

  .ce-gallery,
  .ce-column,
  .ce-left {
    display: block;
    float: none;
    margin-bottom: 0;
  }

  figure {
    margin: 0;
    display: block;
  }

  .slick-slider,
  .ce-row {
    margin-bottom: 0;
  }

  img  {
    display: block;
  }

}